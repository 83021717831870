import { faker } from '@faker-js/faker';

export interface IUser {
    id: string;
    name: string;
    email: string;
    joinedOn: string;
    commentCount: number;
}

export function fetchUsers() {
    const users: IUser[] = [];
    for (let i = 0; i < 10000; i++) {
        users.push({
            id: faker.string.uuid(), // Updated to use faker.string.uuid()
            name: faker.person.fullName(), // faker.name.fullName() is now faker.person.fullName()
            email: faker.internet.email(),
            joinedOn: faker.date.past({ years: 5 }).toISOString(), // Joined in the past 5 years
            commentCount: faker.number.int({ min: 0, max: 500 }) // Updated to faker.number.int()
        });
    }
    return Promise.resolve(users);
}

export const slowSort = (users: IUser[], fn: any) =>{
    const copy = [...users];
    for (let i = 0; i < copy.length-1; i++)
        for (let j = i+1; j < copy.length; j++)
            if (fn(copy[j].commentCount, copy[i].commentCount))
                [copy[i],copy[j]] = [copy[j],copy[i]]
    return copy
}


