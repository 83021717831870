import React, { useState, useEffect } from 'react';
import { slowSort, IUser } from '../services/SortService';
import './Table.css';
// eslint-disable-next-line
import Worker from "worker-loader!../workers/textWorker";


const Table: React.FC = () => {
    const [state, setState] = useState({
        classes: '',
        users: [] as IUser[],
        loading: false,
        view: 'success',
    });

    const worker = new Worker();
    useEffect(() => {
        setState(state =>
            ({
                ...state,
                loading: true,
                view: 'success',
                classes: 'sorting',
            }));
        if (!state.users.length) {
            worker.postMessage({type: 'list'})
            worker.onmessage = (event: any) => {
                console.log('useEffect, recieved: ', event.data);
                setState(state =>
                    ({
                        ...state,
                        users: event.data,
                        loading: false,
                        classes: '',
                    }));
            }
        }
    }, []);


    const clickAny = async () => {
        console.log('clicking.......');
        setState(state =>
        ({
            ...state,
            loading: true,
        }));
        worker.postMessage({type: 'list'})
        worker.onmessage = (event: any) => {
            console.log('Update, recieved: ', event.data);
            setState(state =>
                ({
                    ...state,
                    users: event.data,
                    loading: false,
                }));
        }
        console.log('clicked')
    }

    const updateAsyncUsers = async (sort:any) => {
        worker.postMessage({users: state.users, order:sort, type:'sort'})
        setState(state =>
            ({
                ...state,
                loading: true,
                view: 'success',
                classes: 'sorting',
            }));
        worker.onmessage = (event: any) => {
            setState(state =>
                ({
                    ...state,
                    users: event.data,
                    loading: false,
                    classes: '',
                }));
        }
    }

    const sortAsyncAscending = () => updateAsyncUsers('asc')
    const sortAsyncDescending = () => updateAsyncUsers('desc')

    const updateUsers = async (sort: any) => {
        setState(state =>
            ({
                ...state,
                loading: true,
                view: 'danger',
                classes: 'sorting',
            }));
        const res = await slowSort(state.users, sort);
        setState(state =>
            ({
                ...state,
                users: res,
                loading: false,
                classes: '',
            }));
    }
    const sortDescending = () => updateUsers((a: any, b: any) => a > b )
    const sortAscending = () => updateUsers((a: any, b: any) => a < b )


    const renderUsers = () => {
        return state.users.slice(0,100).map((user, index) => {
            return (
                <div key={index} className="card">
                    <div className="card-header">{user.name}</div>
                    <div className="card-body">
                        <h6 className="card-title">{user.email}</h6>
                        <p className="card-text">{user.joinedOn.toString()}</p>
                        <div className={`alert alert-${state.view}`} role="alert">
                            Comments <span className="badge badge-light">{user.commentCount}</span>
                        </div>
                    </div>
                </div>
            );
        });
    }

    return <div className="container">
        <div className="nav">
            <div className="navBlock">
                <div className="alert alert-success" role="alert">Sort comments with WebWorker</div>
                <div className="btn-group mr-2 mt-2" role="group" aria-label="Async">
                    <button
                        onClick={sortAsyncAscending}
                        type="button"
                        disabled={state.loading}
                        className="btn btn-primary">
                        asc
                    </button>
                    <button
                        onClick={sortAsyncDescending}
                        type="button"
                        disabled={state.loading}
                        className="btn btn-success">
                        desc
                    </button>
                </div>
            </div>
            <div className="navBlock">
                <div className="alert alert-danger" role="alert">Sort comments WITHOUT WebWorker</div>
                <div className="btn-group mr-2 mt-2" role="group" aria-label="Sync">
                    <button
                        onClick={sortAscending}
                        type="button"
                        disabled={state.loading}
                        className="btn btn-primary">
                        asc
                    </button>
                    <button
                        onClick={sortDescending}
                        type="button"
                        disabled={state.loading}
                        className="btn btn-success">
                        desc
                    </button>
                    <button
                        onClick={clickAny}
                        type="button"
                        disabled={state.loading}
                        className="btn btn-info">
                        Update list
                    </button>
                </div>
            </div>
        </div>
        <div className="">
            <div className="progress">
                <div className="progress-bar progress-bar-striped active progress-bar-animated progress" role="progressbar"></div>
            </div>
            <span className="bouncy-ball" role="img" aria-labelledby="jsx-a11y/accessible-emoji">&#129321;</span>
            <span>Users count: {state.users.length} (Only first 100 in list below)</span>
        </div>

        {state.loading &&
            <div className="marginTop">Loading...</div>
        }
        <div className={`marginTop ${state.classes}`}>
            {renderUsers()}
        </div>
    </div>
}

export default Table;
